<template>
  <div>
    <br />
    <div class="d-flex justify-content-center align-items-center p-0">
      <div class="col-auto">
        <span class="p-2">
          Production Référence Nº :
          <span class="fw-bold">
            {{ $route.params.reference }}
          </span>
        </span>
      </div>
    </div>

    <div class="row justify-content-center">
      <div v-if="production.reference" class="col-auto p-1">
        <button
          @click="refresh()"
          class="btn border text-success border-success"
        >
          <i class="bi bi-check2-square text-success fs-4"></i> Valider
        </button>
      </div>

      <div v-if="production.reference" class="col-auto p-1">
        <button @click="refresh()" class="btn border">
          <i class="bi bi-pencil-square text-secondary fs-4"></i> Modifier
        </button>
      </div>
      <div v-if="production.reference" class="col-auto p-1">
        <button
          @click="remove(production.reference)"
          class="btn border border-danger text-danger"
        >
          <i class="bi bi-archive-fill text-danger fs-4"></i> Supprimer
        </button>
      </div>
      <div v-if="production.reference" class="col-auto p-1">
        <button @click="print(production.reference)" class="btn border">
          <i class="bi bi-printer text-secondary fs-4"></i> Imprimer
        </button>
      </div>
      <div class="col-auto p-1">
        <button @click="refresh()" class="btn border">
          <i class="bi bi-arrow-clockwise text-secondary fs-4"></i> Actualiser
        </button>
      </div>
    </div>
    <div class="row align-items-stretch">
      <!-- ***** -->
      <div class="col-6 justify-content-between">
        <div class="row">
          <div class="col p-1">Quantité totale</div>
          <div v-if="production.totalQuantity" class="col p-1 fw-bold">
            {{ production.totalQuantity }}
          </div>
        </div>
      </div>
      <div class="col-6 justify-content-between">
        <div class="row">
          <div class="col p-1">Date</div>
          <div class="col p-1 fw-bold">
            {{ production.date }}
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-stretch">
      <div class="col-6">
        <div class="row justify-content-between">
          <div class="col p-1">Vérifié (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié par :</div>
          <div class="col p-1 fw-bold">
            <span> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié à :</div>
          <div class="col p-1 fw-bold">
            <span> - </span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row justify-content-between">
          <div class="col p-1">Validé (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé par :</div>
          <div class="col p-1 fw-bold">
            <span> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé à :</div>
          <div class="col p-1 fw-bold">
            <span> - </span>
          </div>
        </div>
      </div>
      <div class="col-12 justify-content-between">
        <div class="row">
          <div class="col-3 p-1">Remarque</div>
          <div class="col p-1 fw-bold">
            {{ production.remark }}
          </div>
        </div>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Désignation</th>
          <th scope="col">Quantité</th>
          <th scope="col">Stock</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in production.production_items" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ item.item_reference }}
          </td>
          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td>{{ item.quantity }}</td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>
          <td>
            <span v-if="item.user">
              {{ item.user.name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("production/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("production", {
      production: "getProduction",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch(
        "production/show",
        this.$route.params.reference
      );
    },
  },
};
</script>
